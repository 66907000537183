<template>
  <div class="merch-card">
    <div class="sign" style="margin-bottom: 20px">
      <!-- 商品状态 -->
      <div class="rightBtn">
        <div style="display: flex">
          <div
            v-for="(item, index) in statusList"
            :key="index"
            class="defaultStyle"
            :class="queryParams.type === item.id ? 'opp' : ''"
            @click="StatusValue(item)"
          >
            {{ item.Name }}
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索框 -->
    <el-form ref="queryForm" :model="queryParams" :inline="true">
      <el-form-item prop="orderId">
        <el-input
          v-model="queryParams.orderId"
          placeholder="订单号/主订单号"
          clearable
          type="number"
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item prop="consignee">
        <el-input
          v-model="queryParams.consignee"
          placeholder="收货人或电话"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item prop="enterprise">
        <el-input
          v-model="queryParams.enterprise"
          placeholder="企业名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item prop="payType">
        <el-select
          v-model="queryParams.payType"
          placeholder="支付方式"
          clearable
          size="small"
        >
          <el-option
            v-for="(item, index) in Paymentlist"
            :key="index"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="shareBusinessName" v-if="queryParams.type == '1'">
        <el-input
          v-model="queryParams.shareBusinessName"
          placeholder="共享商家"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item prop="salesmanName">
        <el-input
          v-model="queryParams.salesmanName"
          placeholder="业务员"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item prop="saleBusinessName">
        <el-input
          v-model="queryParams.saleBusinessName"
          placeholder="销售商家"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <div style="margin: 1px" />
      <!-- <el-form-item>
        <el-button
          size="mini"
          @click="querydate('today')"
          :class="queryParams.date === 'date' ? 'color2' : ''"
          >今日
        </el-button>
        <el-button
          size="mini"
          @click="querydate('yesterday')"
          :class="queryParams.date === 'yesterday' ? 'color2' : ''"
          >昨日</el-button
        >
        <el-button
          size="mini"
          @click="querydate('week')"
          :class="queryParams.date === 'week' ? 'color2' : ''"
          >本周
        </el-button>
        <el-button
          size="mini"
          @click="querydate('month')"
          :class="queryParams.date === 'month' ? 'color2' : ''"
          >当月
        </el-button>
        <el-button
          size="mini"
          @click="querydate('lastMonth')"
          :class="queryParams.date === 'lastMonth' ? 'color2' : ''"
          >上月</el-button
        >
      </el-form-item> -->
      <!-- <el-form-item>
        <el-date-picker
          v-model="datelist"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          unlink-panels
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        />
      </el-form-item> -->
      <el-form-item label="订单创建时间">
        <el-date-picker
          clearable
          v-model="datelist"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery">查询</el-button>
        <el-button @click="resetQueryList">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 表格数据 -->
    <div class="dataForm">
      <el-table
        v-loading="loading"
        :data="OrderqueryList"
        :header-cell-style="{ background: '#F2F3F5', color: '#383B46' }"
        border
        style="width: 100%"
        max-height="550"
        ref="tableder"
      >
        <el-table-column align="center" fixed label="序号" width="70" key="1">
          <template slot-scope="scope">
            <p>{{ scope.row.index }}</p>
          </template>
        </el-table-column>
        <el-table-column label="订单编号/创建时间" width="180" key="2">
          <template slot-scope="scope">
            <div>{{ scope.row.orderId }}</div>
            <div class="futle">{{ scope.row.orderCreateTime }}</div>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" width="200" key="3">
          <template slot-scope="scope">
            <div v-if="scope.row.goodsName == null" class="text-center hui">
              ——
            </div>
            <div v-else>
              <div>{{ scope.row.goodsName }}</div>
              <div>{{ scope.row.goodsId }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="manufacturer"
          label="生产厂家"
          align="center"
          width="150"
          key="4"
          show-overflow-tooltip
        />
        <el-table-column
          prop="goodsSpecification"
          label="规格"
          width="150"
          align="center"
          key="5"
          show-overflow-tooltip
        />
        <el-table-column prop="piece" label="件装量" align="center" key="6" />
        <el-table-column
          prop="enterprise"
          label="企业名称"
          align="center"
          width="150"
          key="7"
          show-overflow-tooltip
        />
        <el-table-column label="收货人/联系电话" width="140" key="8">
          <template slot-scope="scope">
            <div>{{ scope.row.consignee }}</div>
            <div>{{ scope.row.mobile }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sellingPrice"
          label="价格(元)"
          align="center"
          key="9"
        />
        <el-table-column
          prop="originalQuantity"
          label="原单数量"
          align="center"
          key="10"
        />
        <el-table-column
          prop="orderQuantity"
          label="下单数量"
          align="center"
          key="11"
        />
        <el-table-column
          prop="outboundQuantity"
          label="发货数量"
          align="center"
          key="12"
        />
        <el-table-column
          prop="afterSaleQuantity"
          label="售后数量"
          align="center"
          key="13"
        />
        <el-table-column
          prop="subtotalAmount"
          label="小计金额 (元)"
          align="center"
          width="150"
          key="14"
        />

        <el-table-column
          prop="platFormHandlingFee"
          label="平台手续费单价(元)"
          align="center"
          width="180"
          key="15"
        />
        <el-table-column
          prop="platFormHandlingFeeAmount"
          label="平台手续费小计(元)"
          align="center"
          width="180"
          key="16"
        />
        <el-table-column
          prop="platformCommission"
          label="平台佣金单价(元)"
          align="center"
          width="180"
          key="17"
          v-if="queryParams.type == '1'"
        />
        <el-table-column
          prop="platformCommissionAmount"
          label="平台佣金小计(元)"
          align="center"
          width="180"
          key="18"
          v-if="queryParams.type == '1'"
        />
        <el-table-column
          prop="totalPlatformCommission"
          label="平台收入合计(元)"
          align="center"
          width="180"
          key="19"
          v-if="queryParams.type == '1'"
        />
        <el-table-column
          prop="commission"
          label="共享佣金单价 (元)"
          align="center"
          width="180"
          v-if="queryParams.type == '1'"
          key="20"
        />
        <el-table-column
          prop="commissionAmount"
          label="共享佣金小计 (元)"
          align="center"
          width="180"
          v-if="queryParams.type == '1'"
          key="21"
        />
        <el-table-column
          prop="productGrossProfit"
          label="商品毛利 (元)"
          align="center"
          width="150"
          v-if="queryParams.type == '1'"
          key="22"
        />
        <el-table-column
          prop="supplier"
          label="共享商家"
          align="center"
          width="180"
          v-if="queryParams.type == '1'"
          key="23"
          show-overflow-tooltip
        />
        <el-table-column
          prop="sellingBusiness"
          label="销售商家"
          align="center"
          width="180"
          key="24"
          show-overflow-tooltip
        />
        <el-table-column
          v-if="queryParams.type == '1'"
          prop="netCommission"
          label="净得佣金 (元)"
          align="center"
          width="150"
          key="25"
        />
        <el-table-column
          prop="salesmanCommission"
          label="业务员佣金单价 (元)"
          align="center"
          width="180"
          key="26"
        />
        <el-table-column
          prop="salesmanCommissionAmount"
          label="业务员佣金小计 (元)"
          align="center"
          width="180"
          key="27"
        />
        <el-table-column
          prop="salesman"
          label="业务员"
          align="center"
          key="28"
        />
        <el-table-column
          prop="payableMethodStr"
          label="支付方式"
          align="center"
          width="150"
          key="29"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.payableMethodStr }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="whiteBarCommission"
          label="白条佣金单价 (元)"
          align="center"
          width="180"
          key="30"
        />
        <el-table-column
          prop="whiteBarCommissionAmount"
          label="白条佣金小计(元)"
          align="center"
          width="180"
          key="31"
        />
        <el-table-column
          prop="whiteClerk"
          label="白条业务员"
          align="center"
          width="100"
          key="32"
        />
        <el-table-column
          prop="productGrossProfit"
          label="商品毛利 (元)"
          align="center"
          width="150"
          v-if="queryParams.type == '0'"
          key="33"
        />
      </el-table>
    </div>
    <div class="merch-page">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, jumper"
        :total="total"
        :page-size="queryParams.size"
        :current-page="queryParams.pageNumber"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const settlement = createNamespacedHelpers("settlement");
const { mapActions } = createNamespacedHelpers("Order");
export default {
  name: "commodinquiry",
  watch: {
    "queryParams.date": {
      handler(oldVal) {
        this.datelist = [this.queryParams.startTime, this.queryParams.endTime];
      },
      deep: true,
    },
    datelist(v) {
      if (v == null) {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
      } else {
        this.queryParams.startTime = v[0];
        this.queryParams.endTime = v[1];
      }
    },
  },
  data() {
    return {
      // 时间选择配置项
      pickerOptions: {
        shortcuts: [],
      },
      loading: false,
      OrderqueryList: [],
      total: 1,
      // 搜索栏
      queryParams: {
        page: 1,
        size: 10,
        orderId: "", // 订单号/主订单号
        consignee: "",
        type: "0", //商品状态
        enterprise: "", //企业名称
        salesmanName: "", //业务员
        shareBusinessName: "", //共享商家
        saleBusinessName: "", //销售商家
        payType: "", //支付方式
        startTime: "", //开始时间
        endTime: "", //结束日期
        date: "", //选择日期
      },
      searchdata: "",
      datelist: [],
      //商品状态
      statusList: [
        { Name: "自营商品", id: "0" },
        { Name: "共享商品", id: "1" },
      ],
      // 支付方式
      Paymentlist: [],
    };
  },
  computed: {
    ...commonIndex.mapState(["detailsSeach"]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.queryParams = this.detailsSeach[router];
      // this.cascaderValue=this.detailsSeach[router].cascaderValue
    }
    this.getList();
    this.getTime();
    //前端获取支付方式列表
    this.getPaymentTypeList().then((res) => {
      if (res.code == 200) {
        this.Paymentlist = res.data;
      }
    });
  },
  methods: {
    ...mapActions(["getbasistime", "getPaymentTypeList"]),
    ...settlement.mapActions(["getsettlementSubCommonList"]),
    ...commonIndex.mapMutations(["changeSeach"]),
    /** 查询列表数据 */
    async getList() {
      this.loading = true;
      var from = { ...this.queryParams };
      if (
        this.queryParams.startTime != "" &&
        this.queryParams.startTime != undefined
      ) {
        from.startTime = this.queryParams.startTime + " 00:00:00";
        from.endTime = this.queryParams.endTime + " 23:59:59";
      }
      let res = await this.getsettlementSubCommonList(from);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.queryParams };
      // save.cascaderValue=this.cascaderValue
      save.router = router;
      this.changeSeach(save);

      res.data.list.forEach((v, i) => {
        let index = (res.data.pageNumber - 1) * res.data.pageSize + i + 1;
        this.$set(v, "index", index);
      });
      this.queryParams.pageNumber = res.data.pageNumber;
      this.OrderqueryList = res.data.list;
      this.total = res.data.totalRecords;
      this.loading = false;
    },
    // 分页
    handleCurrentChange(item) {
      this.queryParams.page = item;
      this.getList();
    },
    // 格式化时间
    timeFormat(date) {
      if (!date || typeof date === "string") {
        this.error("参数异常，请检查...");
      }
      var y = date.getFullYear(); //年
      var m = date.getMonth() + 1; //月
      var d = date.getDate(); //日

      return y + "-" + m + "-" + d;
    },

    //获取这周的周一
    getFirstDayOfWeek() {
      var date = new Date();
      var weekday = date.getDay() || 7; //获取星期几,getDay()返回值是 0（周日） 到 6（周六） 之间的一个整数。0||7为7，即weekday的值为1-7
      date.setDate(date.getDate() - weekday + 1); //往前算（weekday-1）天，年份、月份会自动变化
      date = this.timeFormat(date);
      return date;
    },
    // 获取选择时间
    async getTime() {
      let firstDayOfWeek = this.getFirstDayOfWeek();
      let data = await this.getbasistime();
      if (data.code == 200) {
        this.pickerOptions.shortcuts = [
          {
            text: "今日",
            onClick(picker) {
              picker.$emit("pick", [data.data.today, data.data.today]);
            },
          },
          {
            text: "昨日",
            onClick(picker) {
              picker.$emit("pick", [data.data.yesterday, data.data.yesterday]);
            },
          },
          {
            text: "本周",
            onClick(picker) {
              picker.$emit("pick", [firstDayOfWeek, data.data.today]);
            },
          },
          {
            text: "当月",
            onClick(picker) {
              picker.$emit("pick", data.data.month);
            },
          },
          {
            text: "上月",
            onClick(picker) {
              picker.$emit("pick", data.data.last_month);
            },
          },
        ];
      }
    },
    /** 点击标记 */
    async StatusValue(item) {
      // await this.resetQueryList();
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.queryParams.type = item.id;
      if (this.queryParams.type == "") {
        this.queryParams.type = null;
      }
      this.queryParams.page = 1;
      // this.resetQueryList();
      this.getList();
    },
    /** 日期搜索*/
    querydate(date) {
      var day = new Date();
      switch (date) {
        case "today":
          this.queryParams.startTime = day.format("yyyy-MM-dd 00:00:00");
          this.queryParams.endTime = day.format("yyyy-MM-dd 23:59:59");
          break;
        case "yesterday":
          this.queryParams.startTime = new Date(
            new Date().setDate(new Date().getDate() - 1)
          ).format("yyyy-MM-dd 00:00:00");
          this.queryParams.endTime = new Date(
            new Date().setDate(new Date().getDate() - 1)
          ).format("yyyy-MM-dd 23:59:59");
          break;
        case "week":
          var now = new Date();
          var oneDayTime = 24 * 60 * 60 * 1000;
          var nowTime = now.getTime();
          var dayed = now.getDay();
          var SundayTime = nowTime + (7 - dayed) * oneDayTime;
          var sunday = new Date(SundayTime);
          var nowDayOfWeek = now.getDay() == 0 ? 6 : now.getDay() - 1;
          this.queryParams.startTime =
            this.getDateStr(
              new Date(now.getTime() - nowDayOfWeek * 24 * 60 * 60 * 1000)
            ) + " 00:00:00";
          this.queryParams.endTime = this.getDateStr(sunday) + " 23:59:59";
          break;
        case "month":
          this.queryParams.startTime = new Date(new Date().setDate(1)).format(
            "yyyy-MM-dd 00:00:00"
          );
          this.queryParams.endTime = new Date(
            new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)
          ).format("yyyy-MM-dd 23:59:59");
          break;
        case "lastMonth":
          this.queryParams.startTime = new Date(
            new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
          ).format("yyyy-MM-dd 00:00:00");
          this.queryParams.endTime = new Date(new Date().setDate(0)).format(
            "yyyy-MM-dd 23:59:59"
          );
          break;
      }
      this.queryParams.date = date;
      this.queryParams.page = 1;
      this.getList();
    },
    /** 搜索按键操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按键操作 */
    resetQueryList() {
      let type = this.queryParams.type;
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.queryParams.type = type;
      this.datelist = [];
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.merch-card {
  width: 100%;
  min-width: 1000px;
  max-height: 100%;
  padding: 20px;
  padding-top: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
}
.defaultStyle {
  padding: 10px 20px;
  text-align: center;
  line-height: 100%;
  font-size: 14px;
  cursor: pointer;
  color: rgba(28, 31, 35, 0.6);
}
.opp {
  color: #06b7ae;
  border-radius: 3px;
  background-color: #f2f2f2;
}
.el-form-item {
  margin-bottom: 5px;
}
</style>
